import axios from "axios";
import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../context/ContextGlobal";
import variables from "../../variables.json";
import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddAcademico from './AddAcademico';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { Tooltip } from "@mui/material";
import "./estilos.css";

const ModificaAcademicos = () => {
  const [antecedentesAcademicos, setAntecedentesAcademicos] = useState([]);
  const [movimientoCuenta, setMovimientoCuenta] = useState();
  const [datosPersonales, setDatosPersonales] = useState();
  const { setLoad } = useGlobalContext();

  useEffect(() => {
    setLoad(true);
    const usuario = sessionStorage.getItem("usuario");
    var token = sessionStorage.getItem('token');
    axios
      .get(variables.api + "/api/Cuenta/DatosAcademicos/" + usuario, { headers: { "Authorization": `Bearer ${token}` } })
      .then((response) => {
        setAntecedentesAcademicos(response.data);
        setLoad(false);
      })
      .catch((e) => {
        console.log(e);
        setLoad(false);
      });

    axios
      .get(variables.api + "/api/Cuenta/CuentaIndividual/" + usuario, { headers: { "Authorization": `Bearer ${token}` } })
      .then((response) => {
        setDatosPersonales(response.data.datosPersonales);
      })
      .catch(() => {
        Notify.error("Error al obtener los datos de su cuenta")
      });

    return () => {
      console.log("saliendo");
    };
  }, []);

  const actualizarDatos = () => {
    setLoad(true);
    let datos = {
      usuario: sessionStorage.getItem("usuario"),
      antecedentesAcademicos
    };
    var mensaje = 'Academicos Se Actualiza datos Academicos';
    MovimientoCuenta(mensaje);
    var token = sessionStorage.getItem('token');
    axios.patch(variables.api + "/api/Cuenta/UpdateAcademico", datos, { headers: { "Authorization": `Bearer ${token}` } }).then(() => {
      setLoad(false);
      Notify.success("Se ha actualizado sus antecedentes académicos");
    }).catch(() => {
      setLoad(false);
      Notify.error("Error al actualizar la información");
    });


  };

  const MovimientoCuenta = (info) => {

    var token = sessionStorage.getItem('token');
    var formData = new FormData();
    formData.append("Rut", datosPersonales.rut);
    formData.append("Descripcion", info);

    axios
      .post(variables.api + "/api/Cuenta/MovimientoCuenta", formData, {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        }
      })
      .then((response) => {
        setMovimientoCuenta(response.data);
      })
      .catch((e) => {
        Notify.error("Error en realizar la actualizacion de los datos");
      });

  }

  const actualizarDatosEliminar = (updatedData) => {
    setLoad(true);
    let datos = {
      usuario: sessionStorage.getItem("usuario"),
      antecedentesAcademicos: updatedData
    };
    var token = sessionStorage.getItem('token');
    var mensaje = `Academicos Se Elimino informacion datos Academicos`;
    MovimientoCuenta(mensaje);
    axios.patch(variables.api + "/api/Cuenta/UpdateAcademico", datos, { headers: { "Authorization": `Bearer ${token}` } }).then(() => {
      setLoad(false);
      Notify.success("Se ha actualizado sus antecedentes académicos");
    }).catch(() => {
      setLoad(false);
      Notify.error("Error al actualizar la información");
    });
  };

  const deleteRow = (row) => {
    setLoad(true);
    const updatedData = (antecedentesAcademicos.filter((item) => item.id !== row.id));
    setAntecedentesAcademicos(updatedData);
    Notify.success("Se eliminó el campo de la lista, recuerde guardar los cambios");
    actualizarDatosEliminar(updatedData);
    setLoad(false);
  };

  return (
    <div className="index">
      <hr />
      <center>
        <h4>Datos Académicos</h4>
      </center>
      <div className="alert alert-primary">
        <h5>Importante</h5>
        <p>
          Al momento de agregar un antecedente Academico nuevo, debe seleccionar el boton guardar para que se guarden los cambios
        </p>
      </div>
      {antecedentesAcademicos ? (
        <div>
          <div className="mb-3" style={{ height: 400, width: "100%" }}>
            <Tooltip title="Actualiza los cambios realizados ">
              <button onClick={e => actualizarDatos()} className="btn btn-success m-1">
                Guardar Cambios
              </button>
            </Tooltip>
            <AddAcademico
              setAntecedentesAcademicos={setAntecedentesAcademicos}
              antecedentesAcademicos={antecedentesAcademicos}
            />

            <h5 className="mt-5">Datos de Antecedentes Académicos:</h5>
            <div class="overflow-auto">
              <div className="mb-3" style={{ height: 400, width: "100%" }}>
                <table className="table table-bordered mt-3">
                  <thead>
                    <tr>
                      <th>Establecimiento</th>
                      <th>Nombre del Título</th>
                      <th>Nivel de Estudio</th>
                      <th>Fecha de Obtención</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {antecedentesAcademicos.map((item) => (
                      <tr key={item.id}>
                        <td>{item.casaEstudios}</td>
                        <td>{item.nombreTitulo}</td>
                        <td>{item.tipoTituloNombre}</td>
                        <td>{item.fechaObtencion}</td>
                        <td>
                          <IconButton
                            size="small"
                            aria-label="delete"
                            onClick={() => deleteRow(item)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      ) : (
        "Cargando..."
      )}
      <hr />
    </div>
  );
};

export default ModificaAcademicos;
