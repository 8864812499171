import React, { useState, useEffect } from "react";
import Documentacion from "../../components/Documentacion";
import { useGlobalContext } from "../../context/ContextGlobal";
import axios from 'axios';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import variables from '../../variables.json';

const ModificaDocumentacion = () => {
  const [archivo, setArchivo] = useState(null);
  const [usuario, setUsuario] = useState();
  const { setLoad } = useGlobalContext();
  const [datosPersonales, setDatosPersonales] = useState();

  const estilo = {
    height: "900px",
    width: "90%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  useEffect(() => {
    setUsuario(sessionStorage.getItem("usuario"));
    var token = sessionStorage.getItem('token');
    var user = sessionStorage.getItem("usuario");

    axios
      .get(variables.api + "/api/Cuenta/CuentaIndividual/" + user, { headers: {"Authorization" : `Bearer ${token}`} })
      .then((response) => {
        setDatosPersonales(response.data.datosPersonales);
      })
      .catch(() => {
        Notify.error("Error al obtener los datos de su cuenta")
      });

  }, []);

  const MovimientoCuenta = (info) =>{

    var token = sessionStorage.getItem('token');
    var formData = new FormData();
    formData.append("Rut", datosPersonales.rut);
    formData.append("Descripcion", info);
  
    axios
      .post(variables.api + "/api/Cuenta/MovimientoCuenta", formData, { 
        headers: { 
          "Authorization": `Bearer ${token}`,
          "Content-Type": "multipart/form-data" 
        } 
      })
      .then((response) => {
      })
      .catch((e) => {
        Notify.error("Error en realizar la actualizacion de los datos");
      });

  }

  const actualizarDocumento = () => {
    var token = sessionStorage.getItem('token');
    const formData = new FormData();
    formData.append("archivo", archivo);
    formData.append("usuario", usuario);
    
    var token = sessionStorage.getItem('token');
    axios
      .post(variables.api + "/api/Cuenta/UpdateDocumentacion", formData, { headers: {"Authorization" : `Bearer ${token}`} })
      .then(() => {
        MovimientoCuenta("Se realizo la actualizacion de un de la documentacion")
        Notify.success("Se ha actualizado su documentación");
      })
      .catch(() => Notify.error("Error al actualizar la información"));
  };

  return (
    <div className="index">
    <div className="m-3">
      <input
        onChange={(e) => setArchivo(e.target.files[0])}
        type="file"
        className="form-control"
      />
      <div className="alert alert-warning m-4">Valida que tu archivo antes de subir a la plataforma tenga un un peso maximo de 1.5 mb ya que la plataforma no admite mas</div>
      <button className="btn btn-primary mb-5" onClick={e => actualizarDocumento()}>
        Reemplazar Documento
      </button>
  
        <center>
          <div style={estilo}>
            {
              usuario &&
            <Documentacion data={usuario}></Documentacion>
            }
          </div>
        </center>
      
    </div>

    </div>
  );
};

export default ModificaDocumentacion;
