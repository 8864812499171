import axios from "axios";
import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../context/ContextGlobal";
import variables from "../../variables.json";

import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddConocimiento from "./AddConocimiento";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { Tooltip } from "@mui/material";
import "./estilos.css";

const ModificaConocimientos = () => {
  const [conocimientos, setConocimientos] = useState([]);
  const { setLoad } = useGlobalContext();

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    setLoad(true);
    const usuario = sessionStorage.getItem("usuario");
    axios
      .get(variables.api + "/api/Cuenta/DatosConocimientos/" + usuario, { headers: { "Authorization": `Bearer ${token}` } })
      .then((response) => {
        setConocimientos(response.data);
        setLoad(false);
      })
      .catch(() => {
        setLoad(false);
        Notify.error("Error al cargar la información de conocimientos");
      });

    return () => {
      console.log("saliendo");
    };
  }, []);

  const deleteRow = (row) => {
    setLoad(true);
    const updatedData = conocimientos.filter((item) => item.id !== row.row.id);
    setConocimientos(updatedData);
    Notify.success("Se eliminó el campo de la lista, recuerde guardar los cambios");
    actualizarDatosEliminar(updatedData);
    setLoad(false);
  };

  const actualizarDatos = () => {
    const token = sessionStorage.getItem('token');
    setLoad(true);
    let datos = {
      usuario: sessionStorage.getItem("usuario"),
      conocimientos
    };

    axios.patch(variables.api + "/api/Cuenta/UpdateConocimientos", datos, { headers: { "Authorization": `Bearer ${token}` } })
      .then(() => {
        setLoad(false);
        Notify.success("Se ha actualizado sus conocimientos");
      })
      .catch(() => {
        setLoad(false);
        Notify.error("Error al actualizar la información");
      });
  };

  const actualizarDatosEliminar = (updatedData) => {
    const token = sessionStorage.getItem('token');
    setLoad(true);
    let datos = {
      usuario: sessionStorage.getItem("usuario"),
      conocimientos: updatedData
    };

    axios.patch(variables.api + "/api/Cuenta/UpdateConocimientos", datos, { headers: { "Authorization": `Bearer ${token}` } })
      .then(() => {
        setLoad(false);
        Notify.success("Se ha actualizado sus conocimientos");
      })
      .catch(() => {
        setLoad(false);
        Notify.error("Error al actualizar la información");
      });
  };

  return (
    <div className="index">
      {conocimientos.length > 0 ? (
        <React.Fragment>
          <div className="mb-3" style={{ height: 400, width: "100%" }}>
            <Tooltip title="Actualiza los cambios realizados ">
              <button
                onClick={actualizarDatos}
                className="btn btn-success m-1"
              >
                Guardar Cambios
              </button>
            </Tooltip>
            <AddConocimiento setConocimiento={setConocimientos} />


              <h5 className="mt-5">Datos de Conocimientos:</h5>
              <div class="overflow-auto">
              <div className="mb-3" style={{ height: 400, width: "100%" }}>
                <table className="table table-bordered mt-3">
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Nivel</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {conocimientos.map((item) => (
                      <tr key={item.id}>
                        <td>{item.nombre}</td>
                        <td>{item.nivel}</td>
                        <td>
                          <IconButton
                            size="small"
                            aria-label="delete"
                            onClick={(e) => deleteRow({ row: item })}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        'Cargando...'
      )}
    </div>
  );
};

export default ModificaConocimientos;
