import React, { useEffect, useState } from "react";
import { Button, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';



export default function DataTable({
  conocimiento,
  setConocimiento,
  handleNext
}) {
  useEffect(() => {
    console.log(conocimiento);
  }, []);

  const deleteRow = (row) => {
    setConocimiento(
      conocimiento.filter((item) => item.id != row)
    );
  };

  return (
    <React.Fragment>
      <div class="overflow-auto">
        <div className="mb-3" style={{ height: 400, width: "100%" }}>
          <h5 className="mt-5">Datos de Conocimientos:</h5>
          <table className="table table-bordered mt-3">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Nivel</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {conocimiento.map((item) => (
                <tr key={item.id}>
                  <td>{item.nombre}</td>
                  <td>{item.nivel}</td>
                  <td>
                    <IconButton
                      size="small"
                      aria-label="delete"
                      onClick={() => deleteRow(item.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Button
        className="mt-3"
        type="submit"
        variant="contained"
        disabled={conocimiento.length == 0}
        color="primary"
        onClick={handleNext}
      >
        Siguiente
      </Button>
    </React.Fragment>
  );
}
