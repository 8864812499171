import axios from "axios";
import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../context/ContextGlobal";
import variables from "../../variables.json";
import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddLaboral from "./AddLaboral";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { Tooltip } from "@mui/material";
import "./estilos.css";

const ModificaLaborales = () => {
  const [antecedentesLaborales, setAntecedentesLaborales] = useState([]);
  const { setLoad } = useGlobalContext();

  useEffect(() => {
    var token = sessionStorage.getItem('token');
    setLoad(true);
    const usuario = sessionStorage.getItem("usuario");
    axios
      .get(variables.api + "/api/Cuenta/DatosLaborales/" + usuario, { headers: { "Authorization": `Bearer ${token}` } })
      .then((response) => {
        setAntecedentesLaborales(response.data);
        setLoad(false);
      })
      .catch(() => {
        setLoad(false);
        Notify.error("Error al cargar la información laboral");
      });

    return () => {
      console.log("saliendo");
    };
  }, []);

  const deleteRow = (rowId) => {
    setLoad(true);
    const updatedData = antecedentesLaborales.filter(item => item.id !== rowId);
    setAntecedentesLaborales(updatedData);
    Notify.success("Se eliminó el campo de la lista, recuerde guardar los cambios");
    actualizarDatosEliminar(updatedData);
    setLoad(false);
  };

  const actualizarDatos = async () => {
    var token = await sessionStorage.getItem('token');
    setLoad(true);
    const datos = {
      usuario: sessionStorage.getItem("usuario"),
      antecedentesLaborales
    };
    axios.patch(variables.api + "/api/Cuenta/UpdateLaboral", datos, { headers: { "Authorization": `Bearer ${token}` } }).then(() => {
      setLoad(false);
      Notify.success("Se ha actualizado sus antecedentes laborales");
    }).catch(() => {
      setLoad(false);
      Notify.error("Error al actualizar la información");
    });
  };


  const actualizarDatosEliminar = async (updatedAntecedentesLaborales) => {
    var token = await sessionStorage.getItem('token');
    setLoad(true);
    const datos = {
      usuario: sessionStorage.getItem("usuario"),
      antecedentesLaborales: updatedAntecedentesLaborales
    };
    axios.patch(variables.api + "/api/Cuenta/UpdateLaboral", datos, { headers: { "Authorization": `Bearer ${token}` } }).then(() => {
      setLoad(false);
      Notify.success("Se ha actualizado sus antecedentes laborales");
    }).catch(() => {
      setLoad(false);
      Notify.error("Error al actualizar la información");
    });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90, flex: 0.5, hide: true },
    { field: "empresa", headerName: "Empresa", flex: 1 },
    { field: "descripcionCargo", headerName: "Cargo", flex: 1 },
    { field: "desde", headerName: "Desde", type: "date", flex: 1 },
    { field: "hasta", headerName: "Hasta", type: "date", flex: 1 },
    { field: "referencia", headerName: "Referencia", flex: 1 },
    {
      field: "actions",
      headerName: "Acciones",
      sortable: false,
      width: 160,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <IconButton
            size="small"
            aria-label="delete"
            onClick={() => deleteRow(params.row.id)}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <div className="index">
      <hr />
      <center>
        <h4>Datos Laborales</h4>
      </center>
      <div className="alert alert-primary">
        <h5>Importante</h5>
        <p>
          Al momento de agregar un antecedente laboral nuevo, debe seleccionar el boton guardar para que se guarden los cambios
        </p>
      </div>
      {antecedentesLaborales.length > 0 ? (
        <div>
          <div className="mb-3" style={{ height: 400, width: "100%" }}>
            <Tooltip title="Actualiza los cambios realizados ">
              <button
                onClick={(e) => actualizarDatos(antecedentesLaborales)}
                className="btn btn-success m-1"
              >
                Guardar Cambios
              </button>
            </Tooltip>
            <AddLaboral
              setAntecedentesLaborales={setAntecedentesLaborales}
              antecedentesLaborales={antecedentesLaborales}
            ></AddLaboral>



            <h5 className="mt-5">Datos de Antecedentes Laborales:</h5>
            <div class="overflow-auto">
              <div className="mb-3" style={{ height: 400, width: "100%" }}>
                <table className="table table-bordered mt-3">
                  <thead>
                    <tr>
                      <th>Empresa</th>
                      <th>Cargo</th>
                      <th>Desde</th>
                      <th>Hasta</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {antecedentesLaborales.map((item) => (
                      <tr key={item.id}>
                        <td>{item.empresa}</td>
                        <td>{item.descripcionCargo}</td>
                        <td>{item.desde}</td>
                        <td>{item.hasta}</td>
                        <td>{item.referencia}</td>
                        <td>
                          <IconButton
                            size="small"
                            aria-label="delete"
                            onClick={() => deleteRow(item.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      ) : (
        "Cargando..."
      )}
      <hr />
    </div>
  );
};

export default ModificaLaborales;
