import React, { useState, useEffect } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';



const VerDocumento = ({ data }) => {
    const [archivo, setArchivo] = useState();
    const pdfContentType = 'application/pdf';




    const base64toBlob = (data) => {
        const base64WithoutPrefix = data.substr(`data:${pdfContentType};base64,`.length);

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: pdfContentType });
    };

    useEffect(() => {
        var base64 = "data:application/pdf;base64," + data;
        const url = URL.createObjectURL(base64toBlob(base64));
        console.log(url);
        setArchivo(url);

    }, [data])
    return (
        <div className=''>
            {
                
                archivo &&
                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                    <Viewer fileUrl={archivo}></Viewer>
                </Worker>

            }
        </div>


    )
}

export default VerDocumento;