import axios from "axios";
import React, { useState, useEffect } from "react";
import LeyendaEtapa from "../../components/Leyenda";
import variables from "../../variables.json";
import FullScreenDialog2 from "../../components/FullScreenDialog2";
import FullScreenDialog from "../../components/FullScreenDialog";
import PerfilCargoDialog from "../PerfilCargoDialog";
import VerDocumento from "./VerDocumento";
import { Button } from "@mui/material";
import ModalRight from "../../components/ModalRight";
import './../estilos.css';



const Index = () => {
  const [postulacion, setPostulacion] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [proceso, setProceso] = useState();
  const [openDialogDocumentacion, setOpenDialogDocumentacion] = useState(false);
  const [documento, setDocumento] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openModalForProceso, setOpenModalForProceso] = useState(null);

  const toggleModal = (procesoId) => {
    setOpenModalForProceso(openModalForProceso === procesoId ? null : procesoId);
  };

  useEffect(() => {
    const usuario = sessionStorage.getItem("usuario");
    var token = sessionStorage.getItem('token');
    axios
      .get(variables.api + "/api/ProcesoPersonal?IdPostulante=" + usuario, { headers: { "Authorization": `Bearer ${token}` } })
      .then((response) => {
        setPostulacion(response.data);
      });

  }, []);

  const verDetalle = (p) => {
    console.log(p);
    setProceso(p);
    setOpenDialog(true);
  }

  const handleDocumentacion = (proceso) => {
    const usuario = sessionStorage.getItem("usuario");
    var token = sessionStorage.getItem('token');
    axios
      .get(variables.api + "/api/ProcesoPersonal/ObtenerDocumento/" + usuario + "/" + proceso, { headers: { "Authorization": `Bearer ${token}` } })
      .then((response) => {
        setDocumento(response.data);
        setOpenDialogDocumentacion(true);
      });
  }



  return (
    <div className="index">
      <h1>Mis postulaciones.</h1>
      <div className="alert alert-info">
        <p>El boton <b>Ver mi documentación</b> muestra el archivo que usted subió para el cargo en el que participa.</p>
      </div>
      {
        /*
        <LeyendaEtapa></LeyendaEtapa>
        */
      }
      {postulacion.length > 0 ? (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Nombre Cargo</th>
              <th>Región</th>
              <th>Fecha Postulacion</th>
              {/*
              <th>Filtro Curricular</th>
              <th>Examen</th>
              <th>Evg</th>
              <th>Psicolabolar</th>
              <th>Detalle</th>
              
              */}
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {
              postulacion.map(p => {
                return (
                  <tr key={p.proceso}>
                    <td>{p.nombreCargo}</td>
                    <td>{p.region}</td>
                    <td>{p.fechaPostulacion}</td>
                    {/*
                      <td>
                      <button
                        className={`btn ${p.apruebaFiltro === 'PENDIENTE' ? 'btn-warning' : p.apruebaFiltro === 'TRUE' ? 'btn-success' : 'btn-danger'}`}
                        disabled
                      >
                        {p.apruebaFiltro === 'PENDIENTE' ? 'Pendiente' : p.apruebaFiltro === 'TRUE' ? 'Aprobado' : 'Rechazado'}
                      </button>

                    </td>
                    <td>
                      <button
                        className={`btn ${p.apruebaExamen === 'PENDIENTE' ? 'btn-warning' : p.apruebaExamen === 'TRUE' ? 'btn-success' : 'btn-danger'}`}
                        disabled
                      >
                        {p.apruebaExamen === 'PENDIENTE' ? 'Pendiente' : p.apruebaExamen === 'TRUE' ? 'Aprobado' : 'Rechazado'}
                      </button>

                    </td>
                    <td>
                      <button
                        className={`btn ${p.apruebaEvg === 'PENDIENTE' ? 'btn-warning' : p.apruebaEvg === 'TRUE' ? 'btn-success' : 'btn-danger'}`}
                        disabled
                      >
                        {p.apruebaEvg === 'PENDIENTE' ? 'Pendiente' : p.apruebaEvg === 'TRUE' ? 'Aprobado' : 'Rechazado'}
                      </button>

                    </td>
                    <td>
                      <button
                        className={`btn ${p.apruebaPsicolaboral === 'PENDIENTE' ? 'btn-warning' : p.apruebaPsicolaboral === 'TRUE' ? 'btn-success' : 'btn-danger'}`}
                        disabled
                      >
                        {p.apruebaPsicolaboral === 'PENDIENTE' ? 'Pendiente' : p.apruebaPsicolaboral === 'TRUE' ? 'Aprobado' : 'Rechazado'}
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => toggleModal(p.proceso)} 
                        disabled={p.comentario === "NO INGRESADO"}
                      >
                        Comentario
                      </button>

                      <ModalRight
                        isOpen={openModalForProceso === p.proceso} 
                        onClose={() => toggleModal(p.proceso)}
                      >
                        <h2>Comentarios</h2>
                        <div class="form-group">
                          <label for="exampleFormControlTextarea1">Comentario que explica la razon del rechazo de la postulacion</label>
                          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" disabled>{p.comentario}</textarea>
                        </div>

                      </ModalRight>

                    </td>
                    
                    */}
                    

                    <td className="botonera-tabla">
                      {/*
                        <button className="btn btn-primary mb-1" onClick={() => verDetalle(p)}>Ver Detalle</button>
*/
                      }
                      <FullScreenDialog
                        ButtonOpen="Perfil de cargo"
                        Title="Perfil de cargo"
                      >
                        <PerfilCargoDialog
                          data={p.proceso}
                        ></PerfilCargoDialog>
                      </FullScreenDialog>
                      <Button color="primary" variant="contained" onClick={() => handleDocumentacion(p.proceso)}>Ver mi documentación</Button>
                    </td>

                  </tr>
                );
              })
            }
          </tbody>

        </table>
      ) : (
        <div className="alert alert-danger">
          <h3>Usted no registra postulaciones a algún cargo.</h3>
        </div>
      )}


      <FullScreenDialog2 open={openDialogDocumentacion} setOpen={setOpenDialogDocumentacion}>
        {
          documento &&
          <VerDocumento data={documento}></VerDocumento>
        }
      </FullScreenDialog2>
    </div>
  );
};

export default Index;
