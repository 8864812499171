import React, { useEffect, useState } from "react";
import axios from "axios";
import variables from "../variables.json";
import { Button } from "@mui/material";
import FullScreenDialog from '../components/FullScreenDialog';
import PerfilCargoDialog from "./PerfilCargoDialog";
import { useGlobalContext } from "../context/ContextGlobal";
import { useHistory } from "react-router";
import '../custom.css';
import fotoFondo from '../assets/images/fondo1.jpg'
import leyInclusion from '../assets/files/LEY 20422.pdf';
import InformacionBox from "../components/InformacionBox";
import instructivo from "../assets/files/instructivo.pdf"
import './estilos.css';
import { Notify } from "notiflix";

const Home = () => {
  const { setLoad } = useGlobalContext();
  const [procesos, setProcesos] = useState(null);
  const [preguntaActiva, setPreguntaActiva] = useState({ titulo: null, id: null, open: false });
  const [datosPersonales, setDatosPersonales] = useState();

  let history = useHistory();
  useEffect(() => {
    setLoad(true);
    var data = {
      id: 'e9d69a1b54334da59f7fde53ec76858a',
      secret: '4f9c78e76ec24584bfdc4c643e3ded9c'
    }
    axios.post(variables.api + "/api/Auth", data).then(auth => {
      const usuario = sessionStorage.getItem('usuario');
      if (usuario != null) {
        axios.get(variables.api + "/api/Proceso/" + usuario, { headers: { "Authorization": `Bearer ${auth.data}` } }).then((response) => {
          setProcesos(response.data);
        });
      }
      else {
        axios.get(variables.api + "/api/Proceso/", { headers: { "Authorization": `Bearer ${auth.data}` } }).then((response) => {
          setProcesos(response.data);
        });
      }
    });

    var user = sessionStorage.getItem("usuario");
    const token = sessionStorage.getItem('token');
    if (user != null) {

      axios
        .get(variables.api + "/api/Cuenta/CuentaIndividual/" + user, { headers: { "Authorization": `Bearer ${token}` } })
        .then((response) => {
          setDatosPersonales(response.data.datosPersonales);
        })
        .catch(() => {
          Notify.error("Error al obtener los datos de su cuenta")
        });
    }


    setLoad(false);
  }, []);


  const postular = (id, respuesta) => {
    const usuario = sessionStorage.getItem('usuario');
    if (usuario === null) {
      history.push("/Login");
    } else {
      const formData = new FormData();
      formData.append("idCuenta", usuario);
      formData.append("idPostulacion", id);
      formData.append("respuesta", respuesta)
      var token = sessionStorage.getItem('token');

      MovimientoCuenta(`El usuario: ${datosPersonales.rut} postulo al proceso que es el ID ${id}`);
      axios.post(variables.api + "/api/Postular", formData, { headers: { "Authorization": `Bearer ${token}` } }).then(response => {
        console.log(response);
      })
      setPreguntaActiva({ titulo: null, id: null, open: false });
      Notify.success("Completado", "Ha postulado al cargo, puede verificar el estado de su postulación en el apartado mi cuenta", "success")
    }
  }

  const MovimientoCuenta = (info) => {

    var token = sessionStorage.getItem('token');
    var formData = new FormData();
    formData.append("Rut", datosPersonales.rut);
    formData.append("Descripcion", info);

    axios
      .post(variables.api + "/api/Cuenta/MovimientoCuenta", formData, {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        }
      })
      .then((response) => {
      })
      .catch((e) => {
        Notify.error("Error en realizar la actualizacion de los datos");
      });

  }

  const PreguntaModal = ({ titulo, id, open }) => {
    const [respuesta, setRespuesta] = useState("");
    if (open) {
      return (
        <div className="card-pregunta-modal">
          <div className="card ">
            <div className="card-body">
              <div className="form-group">
                <h3>{titulo}</h3>
                <textarea className="form-control m-2" type="text" value={respuesta} onChange={(e) => setRespuesta(e.target.value)} />
                <Button onClick={e => postular(id, respuesta)} variant="contained" color="primary">
                  Postular
                </Button>
              </div>

            </div>
          </div>
        </div>
      )
    } else {
      return (null)
    }
  }

  const BotonPostular = ({ p }) => {
    const usuario = sessionStorage.getItem('usuario');
    const [modalOpen, setModalOpen] = useState(false);

    const handleLoginRedirect = () => {
      history.push('/login');
    };

    const openModal = () => {
      setModalOpen(true);
    };

    const closeModal = () => {
      setModalOpen(false);
    };

    if (usuario) {
      return (
        <>
          {p.pregunta ? (
            <Button onClick={() => pregunta(p.idPostulacion, p.pregunta)} variant="contained" color="primary">
              Responder y Postular
            </Button>
          ) : (
            <Button onClick={e => postular(p.idPostulacion, "")} variant="contained" color="primary">
              Postular
            </Button>
          )}
        </>
      );
    } else {
      return (

        <div>
          {/* Botón que abre el modal */}
          <button type="button" className="btn btn-primary" onClick={openModal}>
            Ingresar a su sesión
          </button>

          {/* Modal de Bootstrap */}
          {modalOpen && (
            <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">
              {/* Fondo oscuro del modal */}
              <div className="modal-backdrop fade show" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', zIndex: 1040 }} onClick={closeModal}></div>

              {/* Contenido del modal (se coloca sobre el fondo negro) */}
              <div className="modal-dialog modal-dialog-centered" role="document" style={{ zIndex: 1050 }}>
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Ingreso a la sesión</h5>
                  </div><div className="modal-body">
                    <p>Se le informa, estimado usuario de la plataforma de postulaciones, que al registrar una cuenta e iniciar sesión mediante el presente botón, no está llevando a cabo una postulación a ningún proceso. Únicamente está accediendo a su cuenta personal.</p>

                    <div className="alert alert-info">
                      <h5>Le recordamos que la postulación al proceso seleccionado solo se llevará a cabo una vez haya iniciado sesión y posteriormente haga clic en el botón de postular.</h5>
                    </div>

                  </div>

                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={closeModal}>
                      Cerrar
                    </button>
                    <button type="button" className="btn btn-primary" onClick={handleLoginRedirect}>
                      Ingresar
                    </button>
                  </div>
                </div>
              </div>
            </div>

          )}
        </div>
      );
    }
  };



  const pregunta = (id, titulo) => {
    console.log("datos", id, titulo)
    var data = {
      id: id,
      titulo: titulo,
      open: true
    }
    setPreguntaActiva(data);
  }

  return (
    <div className="index">
      <PreguntaModal titulo={preguntaActiva.titulo} open={preguntaActiva.open} id={preguntaActiva.id}></PreguntaModal>
      <h1>Trabaja con nosotros</h1>
      <hr />
      <div className="row">
        <div className="col-lg-6 col-12">
          {variables.plataforma === 'publica' &&
            <React.Fragment>
              <h3>Plataforma de postulaciones.</h3>
              <p>Policía de Investigaciones de Chile es una institución policial de carácter profesional, científico y técnico, responsable de investigar los delitos garantizando el orden y seguridad pública.</p>
              <p>Requerimos personas con alto nivel profesional, técnico y administrativo.</p><p>Si usted desea integrarse a nuestro equipo de trabajo, le invitamos a revisar las ofertas que publicamos regularmente en este sitio:</p>
              <div className="alert alert-warning">En caso de consultas, enviar correo electrónico al indicado en la ficha de postulación</div>
              <a href="/static/media/LEY 20422.af7e08bc.pdf" className="btn btn-secondary col-12 mb-2" target="_blank" rel="noopener noreferrer"> Ley N°20.422 que Establece Normas sobre Igualdad de Oportunidades e Inclusión Social de las Personas con Discapacidad</a>
              <a href={instructivo} className="btn btn-secondary col-12 mb-2" target='_blank' rel='noopener noreferrer'> Instructivo utilización de la plataforma</a>
            </React.Fragment>
          }
          {variables.plataforma == 'interna' &&
            <React.Fragment>
              <h3>Plataforma de postulaciones.</h3>
              <p>
                Policía de Investigaciones de Chile es una institución policial de carácter profesional, científico y técnico, responsable de investigar los delitos garantizando el orden y seguridad pública.
              </p>
              <p>
                Contribuyendo en el fortalecimiento permanente de nuestro Capital Humano con Enfoque de Género, hemos dispuesto la siguiente plataforma, con el objeto de favorecer el desarrollo profesional del personal institucional.
              </p>
              <p>

                Si usted desea conocer los cupos disponibles, le invitamos a revisar las ofertas que publicamos regularmente en este sitio:

              </p>
              <div className="alert alert-warning">

                Dudas o consultas <b>CONTRATACIONESPDI@INVESTIGACIONES.CL</b>
              </div>
              <a href={leyInclusion} className="btn btn-secondary col-12 mb-2" target='_blank' rel='noopener noreferrer'> Ley N°20.422 que Establece Normas sobre Igualdad de Oportunidades e Inclusión Social de las Personas con Discapacidad</a>

            </React.Fragment>
          }
        </div>
        <div className="col-lg-6 col-12">
          {
            /*
            <InformacionBox></InformacionBox>
            <img src={fotoFondo} className="fotoFondo" alt="" />
            */
          }

        </div>
      </div>

      <h2>Procesos Vigentes</h2>
      <hr />
      <div className="alert alert-info">Revisa el perfil de cargo para validar que la documentacion que usted que subio o esta en la plataforma sea la correcta, en caso de no ser la adecuada favor de actualizar sus documentos antes de postular</div>
      {procesos && procesos.length > 0 ? (
        <div className="row">
          {procesos.map((p) => {
            return (
              <div key={p.idPostulacion} className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <div className="card mb-3 h-100">
                  <div className="card-header">
                    <h4>{p.nombreCargo.toUpperCase()}</h4>
                  </div>
                  <div className="card-body">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th>Fecha Cierre</th>
                          <td>{p.fechaCierre}</td>
                        </tr>
                        <tr>
                          <th>Título Requerido</th>
                          <td>{p.tituloRequerido}</td>
                        </tr>
                        <tr>
                          <th>Región</th>
                          <td>{p.region}</td>
                        </tr>
                        <tr>
                          <th>Comuna</th>
                          <td>{p.comuna}</td>
                        </tr>
                        <tr>
                          <th>Experiencia Requerida</th>
                          <td>{p.experiencia} años</td>
                        </tr>
                        <tr>
                          <th>Jornada</th>
                          <td>{p.jornada} horas semanales</td>
                        </tr>
                        <tr>
                          <th>Renta Bruta</th>
                          <td>CLP {p.rentaBruta}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="card-footer card-footer-home">
                    <FullScreenDialog
                      ButtonOpen="Perfil de cargo"
                      Title="Perfil de cargo"
                    >
                      <PerfilCargoDialog
                        data={p.idPostulacion}
                      ></PerfilCargoDialog>
                    </FullScreenDialog>
                    <BotonPostular p={p} />
                  </div>

                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="alert alert-info">
          <h3>Actualmente no hay procesos de postulación disponible</h3>
        </div>
      )}

    </div>
  );
};

export default Home;
