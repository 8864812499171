import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

import { useForm, Controller } from "react-hook-form";

const AddConocimiento = ({ setConocimiento }) => {
  const { handleSubmit, control } = useForm();
  const [open, setOpen] = React.useState(false);
  const [cuenta, setCuenta] = React.useState(1);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data) => {
    data.id = cuenta;
    await setConocimiento((prevState) => [...prevState, data]);
    await setCuenta(cuenta + 1);
    handleClose();
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        + Agregar
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="form-dialog-title">
            Agregar conocimientos o habilidades
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              En esta sección puede incluir estudios y conocimientos que no hayan sido certificados por una institución, por ejemplo puede incluir uso de aplicaciones, idiomas, habilidades especiales, etc...
            </DialogContentText>

            <Controller
              name="nombre"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField
                  className="m-2 form-control"
                  label="Tipo de conocimiento"
                  value={value}
                  onChange={onChange}
                  required
                />
              )}
            />

            <Controller
              name="nivel"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <FormControl className="m-2 form-control">
                  <InputLabel>Nivel de Conocimiento</InputLabel>
                  <Select value={value} onChange={onChange} required>
                    <MenuItem value="">
                      <em>Seleccionar</em>
                    </MenuItem>
                    <MenuItem value={"BASICO"}>Básico</MenuItem>
                    <MenuItem value={"INTERMEDIO"}>Intermedio</MenuItem>
                    <MenuItem value={"AVANZADO"}>Avanzado</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Grabar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddConocimiento;
