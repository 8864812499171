import React, { useEffect, useState } from "react";
import { Button, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

export default function DataTable({
  antecedentesAcademicos,
  setAntecedentesAcademicos,
  handleNext
}) {
  useEffect(() => {
    console.log(antecedentesAcademicos);
  }, [antecedentesAcademicos]);

  const deleteRow = (id) => {
    setAntecedentesAcademicos(
      antecedentesAcademicos.filter((item) => item.id !== id)
    );
  };

  return (
    <React.Fragment>
      <div class="overflow-auto">

        <div className="mb-3" style={{ height: 400, width: "100%" }}>
          <div>
            <div className="mb-3" style={{ height: 400, width: "100%" }}>
              <h5 className="mt-5">Datos de Antecedentes Académicos:</h5>
              <table className="table table-bordered mt-3">
                <thead>
                  <tr>
                    <th>Establecimiento</th>
                    <th>Nombre del Título</th>
                    <th>Nivel de Estudio</th>
                    <th>Fecha de Obtención</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {antecedentesAcademicos.map((item) => (
                    <tr key={item.id}>
                      <td>{item.casaEstudios}</td>
                      <td>{item.nombreTitulo}</td>
                      <td>{item.tipoTituloNombre}</td>
                      <td>{item.fechaObtencion}</td>
                      <td>
                        {/* Botón para eliminar */}
                        <IconButton
                          size="small"
                          aria-label="delete"
                          onClick={() => deleteRow(item.id)} // Eliminar usando el ID
                        >
                          <DeleteIcon />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Button
        className="mt-3"
        type="submit"
        variant="contained"
        disabled={antecedentesAcademicos.length === 0}
        color="primary"
        onClick={handleNext}
      >
        Siguiente
      </Button>
    </React.Fragment>
  );
}
