import React, { useState, useEffect } from 'react';
import axios from 'axios';
import variables from '../variables.json';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';



const Documentacion = ({ data }) => {
    const [archivo, setArchivo] = useState();
    const pdfContentType = 'application/pdf';




    const base64toBlob = (data) => {
        const base64WithoutPrefix = data.substr(`data:${pdfContentType};base64,`.length);

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: pdfContentType });
    };

    useEffect(() => {
        console.log(data);
        var token = sessionStorage.getItem('token');
        axios.get(variables.api + "/api/Cuenta/GetDocumentacion/" + data, { headers: { "Authorization": `Bearer ${token}` } }).then(e => {
            var base64 = "data:application/pdf;base64," + e.data;
            const url = URL.createObjectURL(base64toBlob(base64));
            setArchivo(url);
        }
        ).catch(() => {
            console.log("Error", "Error de comunicación con la base de datos, no se pudo obtener la documentacion del postulante", "error")
        })

    }, [data])
    return (
        <div className='pdfviewer'>
            {
                archivo &&
                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                    <Viewer fileUrl={archivo}></Viewer>
                </Worker>

            }
        </div>


    )
}

export default Documentacion
