import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Icon from '@mui/material/Icon';
import Slide from '@mui/material/Slide';
import { useGlobalContext } from "../context/ContextGlobal";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const estilos = {
  cabecera: {
    display: 'flex'
  },
  centrar: {
    alignItems: 'center',
    textAlign: 'center'
  }
}


export default function AlertDialog() {
  const {showDialog, setShowDialog, alertMensaje, alertTitulo, alertType} = useGlobalContext();

  const handleClose = () => {
    setShowDialog(false);
  };

  const Type = () => {

        switch (alertType){
          case 'error':
            return <center> <Icon color="error" style={{fontSize:'70px', marginTop:'5px'}}>highlight_off</Icon>  </center>
          case 'success':
            return <center> <Icon  style={{fontSize:'70px', marginTop:'5px', color: '#26b52f'}}>check_circle_outline</Icon>  </center>
          case 'warning':
            return <center> <Icon  style={{fontSize:'70px', marginTop:'5px', color: '#fcba03'}}>report_problem</Icon>  </center>
          default:
            return <center> <Icon color="disabled" style={{fontSize:'70px', marginTop:'5px'}}>priority_high</Icon>  </center>
        }
        
  }
  
  return (
    <div>
      <Dialog
        open={showDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Type></Type>
        <h3 style={estilos.centrar}>{alertTitulo}</h3>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {alertMensaje}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}