import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { useGlobalContext } from '../context/ContextGlobal';
import logoPdi from '../assets/images/logo2.png'



const NavMenu = (args) => {



  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  }
  const { loged } = useGlobalContext();
  return (
    <header>

      <nav className="navbar navbar-expand-lg border-bottom box-shadow mb-3 fondo">
        <div className="container-fluid navbarTextos" >
          <a href="/">
          <img className='navbar-brand' src={logoPdi} href="/" height="60px" alt="" />

          </a>

          <button onClick={toggleNavbar}  className="navbar-toggler custom-toggler"  type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={collapsed ? "collapse navbar-collapse" : "show nav-show"} id="navbarTogglerDemo02">
            <ul className="navbar-nav ms-auto text-white">
              <li className="nav-item">
                <a className="nav-link text-white" href="/">Inicio</a>
              </li>
              {
                loged &&
                <React.Fragment>
                  <li className="nav-item">
                    <a className="nav-link text-white" href="/misPostulaciones">Mis Postulaciones</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link text-white" href="/examenes">Exámenes</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link text-white" href="/miCuenta">Mi cuenta</a>
                  </li>
                </React.Fragment>
              }
              {
                loged ?
                  <li className="nav-item">
                    <a className="nav-link text-white" href="/logout">Salir</a>
                  </li>
                  :
                  <li className="nav-item">
                    <a className="nav-link text-white" href="/login">Ingresar</a>
                  </li>
              }
            </ul>
          </div>
        </div>
      </nav>




    </header>
  );
}

export default NavMenu;
