import React, {useEffect} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useGlobalContext } from "../../context/ContextGlobal";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import variables from "../../variables.json";
import { Tooltip } from "@mui/material";

import {
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

import { useForm, Controller } from "react-hook-form";
import axios from "axios";

export default function FormDialog({
  setAntecedentesAcademicos,
}) {
  const { setLoad, alertDialog, setSessionUser, setLoged } = useGlobalContext();
  const { handleSubmit, control, watch } = useForm();
  const [open, setOpen] = React.useState(false);
  const [cuenta, setCuenta] = React.useState(1);
  const [tipoTitulo, setTipoTitulo] = React.useState(null);
  const [tipoEstablecimiento, setTipoEstablecimiento] = React.useState(null);
  
 
  useEffect(() => {
    const getTitulos = () => {
      var token = sessionStorage.getItem('token');
      axios.get(variables.api + "/api/Parametrica/TiposTitulos", { headers: {"Authorization" : `Bearer ${token}`} }).then(response => {
        setTipoTitulo(response.data);
      });
    }

    const getTipoEstablecimiento = () => {
      var token = sessionStorage.getItem('token');
      axios.get(variables.api + "/api/Parametrica/TiposEstablecimientos", { headers: {"Authorization" : `Bearer ${token}`} }).then(response => {
        setTipoEstablecimiento(response.data);
      });
    }

    if(tipoTitulo == null){
      getTitulos();
    }

    if(tipoEstablecimiento == null){
      getTipoEstablecimiento();
    }
  }, [])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data) => {

    //data.fecha obtencion < hoy.
   // var hoy = new Date("Y-m-d");

   // if(data.fecha >= hoy){
      data.id = cuenta;
      data.tipoTituloNombre = tipoTitulo.find(e => (e.value == data.tipoTitulo)).text;
      data.tipoEstablecimientoNombre = tipoEstablecimiento.find(e => (e.value == data.tipoEstablecimiento)).text;
      data.tipoTitulo = String(data.tipoTitulo);
      data.semestres = parseInt(data.semestres);
      await setAntecedentesAcademicos((prevState) => [...prevState, data]);
      await setCuenta(cuenta + 1);
      handleClose();
  //  }else{
  //    alertDialog("Error", "La fecha no puede ser mayor a la actual" + data.fecha +" / "+ hoy, "error");
  //    handleClose();
   // }

   
  };

  return (
    <div>
      <Tooltip title="Agrega un nuevo antecedente académico">
      <Button className="m-1" variant="outlined" color="primary" onClick={handleClickOpen}>
        + Agregar
      </Button>
      </Tooltip>
      
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="form-dialog-title">
            Agregar Título Universitario
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ingrese los campos requeridos a continuación, por cada antecedente academico que
              desee informar.
            </DialogContentText>

            <Controller
              name="tipoEstablecimiento"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <FormControl className="m-2 form-control">
                  <InputLabel>Tipo de establecimiento</InputLabel>
                  <Select value={value} onChange={onChange} required>
                    <MenuItem value="">
                      <em>Seleccionar</em>
                    </MenuItem>
                    {
                      tipoEstablecimiento && 
                      tipoEstablecimiento.map(e => {
                        return <MenuItem key={e.value} value={parseInt(e.value)}>{e.text}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
              )}
            />



            <Controller
              name="casaEstudios"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField
                  className="m-2 form-control"
                  label="Nombre del establecimiento"
                  value={value}
                  onChange={onChange}
                  required
                />
              )}
            />

            <Controller
              name="tipoTitulo"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <FormControl className="m-2 form-control">
                  <InputLabel>Nivel de estudio</InputLabel>
                  <Select value={value} onChange={onChange} required>
                    <MenuItem value="">
                      <em>Seleccionar</em>
                    </MenuItem>
                    {
                      tipoTitulo && 
                      tipoTitulo.map(t => {
                        return <MenuItem key={t.value} value={parseInt(t.value)}>{t.text}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
              )}
            />

            <Controller
              name="nombreTitulo"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField
                  className="m-2 form-control"
                  label="Nombre del Título"
                  value={value}
                  onChange={onChange}
                  required
                />
              )}
            />


            
          <Controller
              name="semestres"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField
                  type="number"
                  className="m-2 form-control"
                  label="Cantidad de semestres"
                  value={value}
                  onChange={onChange}
                  required
                  InputProps={{ inputProps: { min: 0} }}

                />
              )}
            />

            <Controller
              name="fechaObtencion"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  className="m-2 form-control"
                  label="Fecha de Obtención"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  required
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Grabar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
