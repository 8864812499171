import React, { useState } from "react";
import ModificaAcademicos from "./ModificaAcademicos";
import ModificaConocimientos from "./ModificaConocimientos";
import ModificaLaborales from "./ModificaLaborales";
import ModificaPersonales from "./ModificaPersonales";
import ModificaDocumentacion from './ModificaDocumentacion';

const MiCuenta = () => {
  const [showPersonales, setShowPersonales] = useState(false);
  const [showLaborales, setShowLaborales] = useState(false);
  const [showAcademicos, setShowAcademicos] = useState(false);
  const [showConocimientos, setShowConocimientos] = useState(false);
  const [showDocumentacion, setShowDocumentacion] = useState(false);

  const datosPersonalesShow = () => {
    setShowPersonales(true);
    setShowLaborales(false);
    setShowAcademicos(false);
    setShowConocimientos(false);
    setShowDocumentacion(false);
  };
  const datosLaboralesShow = () => {
    setShowPersonales(false);
    setShowLaborales(true);
    setShowAcademicos(false);
    setShowConocimientos(false);
    setShowDocumentacion(false);
  };
  const datosAcademicosShow = () => {
    setShowPersonales(false);
    setShowLaborales(false);
    setShowAcademicos(true);
    setShowConocimientos(false);
    setShowDocumentacion(false);
  };
  const datosConocimientosShow = () => {
    setShowPersonales(false);
    setShowLaborales(false);
    setShowAcademicos(false);
    setShowConocimientos(true);
    setShowDocumentacion(false);
  };

  const datosDocumentacionShow = () => {
    setShowPersonales(false);
    setShowLaborales(false);
    setShowAcademicos(false);
    setShowConocimientos(false);
    setShowDocumentacion(true);
  };

  return (
    <div>
      <div className="alert alert-info">
        <h2>Importante</h2>
        <p>
          Cuando modifique su cuenta personal, deberá volver a postular al cargo
          que desee participar, con el fin de modificar los datos que serán
          revisados, ya que el sistema guarda una versión de su{" "}
          <b>Currículo Virtual</b> cada vez que usted postula a un cargo
          diferente{" "}
        </p>
      </div>
      <button
        className="btn btn-secondary ms-1"
        onClick={() => datosPersonalesShow()}
      >
        Datos Personales
      </button>
      <button
        className="btn btn-secondary ms-1"
        onClick={() => datosAcademicosShow()}
      >
        Datos Académicos
      </button>
      <button
        className="btn btn-secondary ms-1"
        onClick={() => datosLaboralesShow()}
      >
        Datos Laborales
      </button>
      <button
        className="btn btn-secondary ms-1"
        onClick={() => datosConocimientosShow()}
      >
        Conocimientos
      </button>
      <button
        className="btn btn-secondary ms-1"
        onClick={() => datosDocumentacionShow()}
      >
        Documentación
      </button>
      {showPersonales && <ModificaPersonales></ModificaPersonales>}
      {showLaborales && <ModificaLaborales></ModificaLaborales>}
      {showAcademicos && <ModificaAcademicos></ModificaAcademicos>}
      {showConocimientos && <ModificaConocimientos></ModificaConocimientos>}
      {showDocumentacion && <ModificaDocumentacion></ModificaDocumentacion>}
    </div>
  );
};

export default MiCuenta;
