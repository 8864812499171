import React from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button } from "@mui/material";
import { useGlobalContext } from "../../context/ContextGlobal";



const AddDocumentacion = ({ sendData }) => {
  const { alertDialog } = useGlobalContext();
  const onSubmit = async (data) => {
    const max_size = 1500000;
    if (data.attachments[0].size > max_size) {
      alertDialog("error", "el tamaño del archivo excede el máximo permitido", "error");
      return false;
    }
    await sendData(data.attachments[0]);
  };

  const { handleSubmit, control } = useForm();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="alert alert-info">
        <h3>
        Deberá adjuntar toda la documentación indicada en la Ficha de Postulación en un único archivo en formato PDF, con un tamaño máximo de 1 MB. Si el archivo supera este límite, puede utilizar herramientas de compresión para reducir su tamaño.
        </h3>
      </div>

      <Controller
        name="attachments"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField type={"file"} onChange={(e) => {
            field.onChange(e.target.files);
          }} required inputProps={{ accept: "application/pdf" }} />

        )}
      />
      <br />
      <Button className="m-2" type="submit" variant="contained" color="primary">Finalizar</Button>
    </form>
  );
};

export default AddDocumentacion;
