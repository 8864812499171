import React, { useEffect, useState } from 'react'
import "./Footer.css";
import PinDropIcon from '@mui/icons-material/PinDrop';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EmailIcon from '@mui/icons-material/Email';
import variables from "../variables.json";
import axios from 'axios';

const Footer = () => {
    const [visitas, setVisitas] = useState(0);

    useEffect(() => {
        var token = sessionStorage.getItem('token');
        axios
            .post(variables.api + "/api/Visitas", { headers: { "Authorization": `Bearer ${token}` } })
            .then((response) => {
                setVisitas(response.data);
            });
    }, []);

    return (
        <div className="pdi-footer">
            
            <div className="pdi-footer-content">
                <div>
                    <RemoveRedEyeIcon /> Visitas {visitas}
                </div>
                <div>
                    <PinDropIcon/> GENERAL MACKENNA 1370
                </div>
            </div>
        </div>
    )
}

export default Footer