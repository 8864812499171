import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { esES } from "@mui/material/locale";
import { GoogleOAuthProvider } from '@react-oauth/google';


const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
      secondary: {main: "#7a8691", light: '#7a8691', dark: "#7a8691"}
    },
  },
  esES
);

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");


ReactDOM.createRoot(document.getElementById('root')).render(
  <GoogleOAuthProvider clientId="855828938729-ccebri29jt3e382sbamote474s0t99r9.apps.googleusercontent.com">
  <ThemeProvider theme={theme}>
    <BrowserRouter basename={baseUrl}>
        <Routes />
    </BrowserRouter>
  </ThemeProvider>
  </GoogleOAuthProvider>,
);


