import React, {useState} from "react";
import {
  Button,
  TextField,
  Input,
  InputLabel,
  InputAdornment,
  IconButton,
  FormControl,
} from "@mui/material";

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useForm, Controller } from "react-hook-form";
import ValidaRut from "../../components/ValidaRut";
import { useGlobalContext } from "../../context/ContextGlobal";

const NuevaCuenta = ({ values, setValues, handleNext }) => {
  const {alertDialog} = useGlobalContext();
  const { handleSubmit, control } = useForm();
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowRePassword = () => {
    setValues({ ...values, showRePassword: !values.showRePassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownRePassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (data) => {

    if(data.password !== data.repassword){
        alertDialog("Error","La contraseña no coincide","error");
        return false;
    }

    if(!rutValido){
        alertDialog("Error","Debe ingresar un RUT válido","error");
        return false;
    }

    setValues((prevState) => ({...prevState, password: data.password, rut: data.rut, correo: data.correo}));
    handleNext();
    /*
    const formData = new FormData();
    formData.append("user", data.correo);
    formData.append("pass", data.password);
    formData.append("rut", data.rut);
    axios.post(variables.api + "/api/Cuenta/Create", formData).then(response => {
        if(response.data === "rut"){
            alertDialog("Error","Este RUT ya se encuentra registrado, intente recuperar su contraseña","error");
        }else if( response.data === "correo"){
            alertDialog("Error","Este Correo ya se encuentra registrado, intente recuperar su contraseña","error");
        }else if( response.data === "error"){
            alertDialog("Error","No se pudo completar la acción, se produjo un error interno, intenteló nuevamente","error");
        }else{
            handleNext();
        }
    }).catch(error =>  { console.log(error)});
    */
  };

  const [rutValido,setRutValido] = useState(false);

  return (
    <div>
      
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="rut"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <ValidaRut value={value} onChange={onChange} onValid={setRutValido}>
                <TextField label="" className="m-1 col-12 col-lg-6" required></TextField>
            </ValidaRut>

          )}
        />
        {!rutValido&& <p className="text-danger">Rut Inválido</p>}
        <Controller
          name="correo"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TextField
              className="m-1 col-12 col-lg-6"
              label="correo"
              value={value}
              onChange={onChange}
              type="email"
              required
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <FormControl className="m-1 col-lg-6  col-12">
              <InputLabel htmlFor="standard-adornment-password">
                Contraseña
              </InputLabel>
              <Input
                required
                id="standard-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={value}
                onChange={onChange}
                inputProps={{ minLength: 8, maxLength: 20 }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          )}
        />

        <Controller
          name="repassword"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <FormControl className="m-1 col-lg-6  col-12">
              <InputLabel htmlFor="standard-adornment-password">
                Repetir Contraseña
              </InputLabel>
              <Input
                required
                id="standard-adornment-password"
                type={values.showRePassword ? "text" : "password"}
                value={value}
                onChange={onChange}
                inputProps={{ minLength: 8, maxLength: 20 }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowRePassword}
                      onMouseDown={handleMouseDownRePassword}
                    >
                      {values.showRePassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          )}
        />
        <div className="mt-3">
          <Button type="submit" variant="contained" color="primary">
            Siguiente
          </Button>
        </div>
      </form>
    </div>
  );
};

export default NuevaCuenta;
