import React from 'react';
import Layout from './components/Layout';
import  Home  from './pages/Home.jsx';
import Login from './pages/Login/Index';
import Login2 from './pages/Login/Index2';
import Logout from './pages/Login/Logout';
import { Switch, Route } from "react-router-dom";
import {makeStyles} from "@mui/styles";
import { Backdrop, CircularProgress } from "@mui/material";
import { useGlobalContext, GlobalProvider } from "./context/ContextGlobal";
import AlertDialog from "./components/AlertDialog";
import MisPostulaciones from "./pages/Cupo/Index.jsx";
import NuevaCuenta from './pages/Login/New'
import LostAccount from './pages/Login/LostAccount.jsx'
import RecoverPassword from './pages/Login/RecoverPassword'
import Examenes from './pages/Examenes/Index'
import MiCuenta from './pages/Login/MiCuenta';
import IniciarTest from './pages/Examenes/IniciarTest';
import FinalizarExamen from './pages/Examenes/FinalizarExamen';
import Suspendido from './pages/Examenes/Suspendido';
import Deshabilitado from './pages/Examenes/Deshabilitado';
import Expira from './pages/Examenes/Expira';



import './custom.css'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Routes = () => {
  const classes = useStyles();
  const {load, alertMensaje, alertTitulo, showDialog} = useGlobalContext();
    return (
      <Layout>
         <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/Login' component={Login} />
            <Route path='/Login2' component={Login2} />
            <Route path='/NewAccount' component={NuevaCuenta} />
            <Route path='/Logout' component={Logout} />
            <Route path='/MisPostulaciones' component={MisPostulaciones} />
            <Route path="/RecoverPassword/:id" component={RecoverPassword} />
            <Route path='/lostaccount' component={LostAccount} />
            <Route path="/examenes" component={Examenes}></Route>
            <Route path="/miCuenta" component={MiCuenta}></Route>
            <Route path="/iniciarTest" component={IniciarTest}></Route>
            <Route path="/Examen/FinalizarExamen" component={FinalizarExamen}></Route>
            <Route path="/Examen/Suspendido" component={Suspendido}></Route>
            <Route path="/Examen/Deshabilitado" component={Deshabilitado}></Route>
            <Route path="/Examen/Expira" component={Expira}></Route>
         </Switch>
          <Backdrop className={classes.backdrop} open={load}>
            <CircularProgress color="inherit" />
          </Backdrop>
         <AlertDialog show={showDialog} titulo={alertTitulo} mensaje={alertMensaje}></AlertDialog>
      </Layout>
    );
}

export default (props) => (
  <GlobalProvider>
    <Routes>{props.children}</Routes>
  </GlobalProvider>
);
